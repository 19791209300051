



















































import { AuthenticatePayload } from "@/store/actions";
import { Component, Vue } from "vue-property-decorator";
import { Action } from "vuex-class";

@Component
export default class LoginView extends Vue {
  @Action("authenticate")
  authenticate!: (payload: AuthenticatePayload) => void;
  valid = true;
  error = "";
  email = "";
  password = "";

  emailRules = [
    (v: string): string | boolean => !!v || "Email is required",
    (v: string): string | boolean =>
      /.+@.+\..+/.test(v) || "Email must be valid",
  ];

  passwordRules = [
    (v: string): string | boolean => !!v || "Password is required",
  ];

  async onLogin(): Promise<void> {
    this.error = "";
    if (!this.valid) return;
    try {
      await this.authenticate({ email: this.email, password: this.password });
      this.$router.push("/");
    } catch (e) {
      this.error = "Email or Password wrong";
    }
  }
}
